.WeekView_Container{
    color:white;
    background-color:transparent;
    width:33vw;
    height: auto;
    /*border: 1px solid rgb(0,0,0,0.2);
    border-right-style: groove;
    border-right-width:20px; */
   
    
  
  
   
    
  
    
}

.WeekView_Banner{
  
    background-color: #333333;
    border-radius: 15px;
    height:25%;
    
    box-shadow: 3px 0px 15px 3px black;
    
    
    
}

.Week_Header{
    font-size:16px;
    font-family: 'Anton', sans-serif;
    text-align:center;   

}



.WeekView_Inner_Container{
    background-color: gray;
    border-radius:15px;
    height: auto; 
    width:auto; 
   
   
    
    
   
}    

.WeekView_Picks{
    list-style-type:none;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
    color: white;
    width: 25%;
    height:auto;
    border-radius:5px;  
    text-align: center;
    
}

.WeekView_Spread{
    list-style-type:none;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
    color: white;
    width: 25%;
    height:auto;
    border-radius:5px;  
    text-align:center;
    
}



.WeekView_Total{
    list-style-type:none;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
    color: white;
    width: 25%;
    height:auto;
    border-radius:5px;  
    text-align: center;
}

.Picks_Title{
    color:#cc9933;
    font-size:16px;
    stroke-width: 2px;
    display:flex;
    justify-content:center;
   
  
    
}

.Spread_Layout{
    display:flex;
    flex-flow:row; 
    justify-content: center;
}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .WeekView_Container{
        color:white;
        width:auto;
        height: auto;
        border-radius: 1px;
        z-index:0;  
        overflow-y:auto;
        overflow-x:hidden;
        
    }

    
   
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}



