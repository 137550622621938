.Panel_Container{
    color:white;
    background-color:#cc9933;
    width:33%;
    height: 100vh; /*come back to this*/
    border: 1px solid rgb(0,0,0,0.2);
    border-right-style: groove;
    border-right-width:20px; 
    border-radius: 1px;
    z-index:0;  
    overflow-y:auto;
    overflow-x:hidden;
    margin-top:-.1%;
    
}

.Panel_Banner{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #333333;
    border-radius: 5px;
    height:35%;
    box-shadow: 3px 0px 15px 3px black;
  
    
}

.Name_Tag{
    font-size:32px;
    font-family: 'Anton', sans-serif;
    text-align:center;   
}



.Profile_Pictures{
    width: 150px;
    height:150px;
    border-radius:25px;   
}

.Panel_Inner_Container{
    background-color: gray;
    border-radius:15px;
    height: auto; 
    width:auto;  
}    

.Panel_Picks{
    list-style-type:none;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
    color: white;
    width: 25%;
    height:auto;
    text-align: center;
    
}


.Panel_Weeks{
    list-style-type:none;
    color: white;
    width: auto;
    height:auto;
    text-align: center;
    
    
    
}

.Panel_Current_Week_Container{
    padding:5px;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border: 1px solid #333333;  
    box-shadow: 1px 1px 1px 1px black;
    border-radius:5px;
    color:white;
   
    
    
   
}

.Panel_Spread{
    list-style-type:none;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
    color: white;
    width: 25%;
    height:auto;
    border-radius:5px;  
    text-align:center;
    
}



.Panel_Total{
    list-style-type:none;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
    color: white;
    width: 25%;
    height:auto;
    border-radius:5px;  
    text-align: center;
}

.Picks_Title{
    color:#cc9933;
    font-size:16px;
    stroke-width: 2px;
    display:flex;
    justify-content:center;
   
  
    
}

.Spread_Layout{
    display:flex;
    flex-flow:row; 
    justify-content: center;
   
}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .Panel_Container{
        color:white;
        background-color:#cc9933;
        width:auto;
        height: auto;
        border: 1px solid rgb(0,0,0,0.2);
        border-radius: 1px;
        z-index:0;  
        overflow-y:auto;
        overflow-x:hidden;
        
    }

    
   
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}



