.SocialMedia_Container{
  
    
    color:white;
    /*background-color:rgba(44, 43, 43, 0.3);*/
    width: auto;
    height: auto;
    z-index:4;
    border-radius:10px;
    text-align:center;
   
    
    
}

.Icon_Group{
   display:flex;
   flex-direction:column-reverse;
   flex-wrap:row nowrap;
   
   

}



@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
   
     

  }





