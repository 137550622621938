.Stats_Container{
    display:flex;
    flex-direction:column;
    color:white;
    background-color:rgb(44, 43, 43);

    width: auto;
    height:100vh; /*fix later */
    z-index:3;
    
}

.Stats_Inner_Container{
    background-color: #514d4a; 
    margin:auto;
    
} 

.Game_Box{
   
    height:125px;
    width:auto;
    background-color:#333333 ;
}

.Text{
    font-size:12px;
    margin-left:15px;
    
}

.Game_Title{
  text-align:center;
  font-family: 'Anton', sans-serif;
  font-size:24px;
}

h5{
  text-align:center;
}



.Scroller {
    margin: 0 auto;
    height: auto;
    width: auto;
    min-width:125px;
    overflow: auto;
    list-style-type:none;
  
  }


  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:#333333; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #666666; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #cc9933;
   
  }

  
  .Item {
    margin: px 0;
  }