.Video{
    display:flex;
    justify-content: center;
    margin:5px;
    border-radius:10px;
    
   
}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
   .Video{
       display:flex;
       justify-content: center;
       width:auto;
       height:auto; 
     
   }

    
   
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .Video{
      display:flex;
      justify-content: center;
      align-content: center;
      width:auto;
      height:auto;
      margin: 10px;
      
    
  }
}



