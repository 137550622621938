.Selection_Container{
    display:flex;
    flex-direction:row-reverse;
    justify-content: flex-end;
    color:white;
    width: auto;
    height: auto;
    border-radius:10px;
    
}

.Selection_Inner_Container{
    background-color: #514d4a;
    width: auto;
    height: auto;  
    margin-top:0;
    margin-right:auto;
    margin-left:auto;
  
}   


.Radio{
    background-color: rgb(105, 103, 97,0.5);
    width:auto;
    height:auto;
    border-radius:1px;
    
}

.Matches{
    list-style-type:none;
    
}

.Button_Small{
    width:auto;
    height:auto;
    background-color: #cc9933;
    border-radius: 4px;
    color:white;
}

.Button_Large{
    widows:auto;
    height:auto;
    background-color: #333333;
    border-color:#333333;
   
}

.Picks{
    color: #cc9933;
    list-style-type:none;
    background-color:rgb(0,0,0,0.5);;
    border: 0px solid rgb(0,0,0,0.5);
    border-right-style: groove;
    border-right-width:20px; 
}

.Spread{
    list-style-type:none;
    background-color:rgb(0,0,0,0.5);
    border: 0px solid rgb(0,0,0,0.4);
    border-right-style: groove;
    border-right-width:5px; 
}

.Spread_Scroller{
    margin: 0 auto;
    height: auto;
    width: auto;
    min-width:150px;
    overflow: auto;
    list-style-type:none;
}