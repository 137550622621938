.CurrentEvent_Container{

    color:white;
  /* background-color:rgb(44, 43, 43, 0.5);*/
    width: 50%;
    height:auto;
    font-size:32px;
    font-family: 'Anton', sans-serif;
    line-height:20px;
    border-radius:5px;
 
  
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .CurrentEvent_Container{

        color:white;
      /* background-color:rgb(44, 43, 43, 0.5);*/
        width: 75%;;
        height:auto;
        font-size:24px;
        font-family: 'Anton', sans-serif;
        line-height:7px;
        border-radius:5px;
      
       
       
      
    }
    

  }
  