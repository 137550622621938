.Container{
    display:flex;
    justify-items: center;
    flex-direction: row;    
    color:white;
    height:100%;
    width:100%;
    background-color:#333333;
    z-index: 1;  
    overflow-x: hidden;

    
    
}

.innerContainer{
  width:100vw;
  z-index:1;
}   

.Games_btn {
 width:20px;
 height:80vh;
 font-size:24px;
 background-color:rgb(51, 51, 51, 0.0);
 border:none;
 color:white;
 transform: rotate(-90deg);
 font-family: 'Anton', sans-serif;

  outline: none;
  
 
 

}




@keyframes game-slide{

  from {right: 200px; width:0px}
    to {right: 0px; width:100px}
 
}

.Banner_Container{
    z-index:2;
    
}

.Panels{
    display:flex;
    justify-items: center;
    flex-direction: row;
    z-index:1;
}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
   .Container{
    display:flex;
    flex-direction:column;
   
   }

   .Panels{
    display:flex;
    justify-content: center; 
    flex-direction: column;
  
    

    
   }

   .Games{
       visibility: hidden;
       width:0px;
       height:0px;
   }

   .Games_btn{
    visibility: hidden;
    width:0px;
    height:0px;
   }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}


/*yellow : #cc9933*/