.Tab_Container{
   
    padding:5px;
    background-color:rgb(0,0,0,0.7);
    border: 0px solid rgb(0,0,0,0.4);
    border: 1px solid #333333;  
    box-shadow: 1px 1px 1px 1px black;
    border-radius:5px;
    color:white;
    
    
    
}


.Tab_Button_Open{
    
   background-color:transparent;
   color:white;
    border:0;
   width:33vw;
   padding:5px
 

}


.Tab_Button_Close{
    
    background-color:#cc9933;
    border: 1px solid white;  
    box-shadow: 1px 1px 1px 1px black;
    border-radius:5px;
    color:white;
    padding:5px;
    
   
  
 
 }

 .Tab_Button_Close:hover, .Tab_Button_Open:hover{
    background-color:#333333;
    border-radius:5px;
    padding:5px;
    
    
 }




