/*ANIMATION*/

@keyframes cycle {
	0%  { top:0px; }
	4%  { top:0px; } 
	16% { top:0px; opacity:1; z-index:0; } 
	20% { top:325px; opacity:0; z-index:0; } 
	21% { top:-325px; opacity:0; z-index:-1; }
	92% { top:-325px; opacity:0; z-index:0; }
	96% { top:-325px; opacity:0; }
	100%{ top:0px; opacity:1; }
	
}
@keyframes cycletwo {
	0%  { top:-325px; opacity:0; }
	16% { top:-325px; opacity:0; }
	20% { top:0px; opacity:1; }
	24% { top:0px; opacity:1; } 
	36% { top:0px; opacity:1; z-index:0; } 
	40% { top:325px; opacity:0; z-index:0; }
	41% { top:-325px; opacity:0; z-index:-1; } 
	100%{ top:-325px; opacity:0; z-index:-1; }
}
@keyframes cyclethree {
	0%  { top:-325px; opacity:0; }
	36% { top:-325px; opacity:0; }
	40% { top:0px; opacity:1; }
	44% { top:0px; opacity:1; } 
	56% { top:0px; opacity:1; } 
	60% { top:325px; opacity:0; z-index:0; }
	61% { top:-325px; opacity:0; z-index:-1; } 
	100%{ top:-325px; opacity:0; z-index:-1; }
}
@keyframes cyclefour {
	0%  { top:-325px; opacity:0; }
	56% { top:-325px; opacity:0; }
	60% { top:0px; opacity:1; }
	64% { top:0px; opacity:1; }
	76% { top:0px; opacity:1; z-index:0; }
	80% { top:325px; opacity:0; z-index:0; }
	81% { top:-325px; opacity:0; z-index:-1; }
	100%{ top:-325px; opacity:0; z-index:-1; }
}
@keyframes cyclefive {
	0%  { top:-325px; opacity:0; }
	76% { top:-325px; opacity:0; }
	80% { top:0px; opacity:1; }
	84% { top:0px; opacity:1; }
	96% { top:0px; opacity:1; z-index:0; }
	100%{ top:325px; opacity:0; z-index:0; }
}

@keyframes cycle {
	0%  { top:0px; }
	4%  { top:0px; }
	16% { top:0px; opacity:1; z-index:0; } 
	20% { top:325px; opacity:0; z-index:0; }
	21% { top:-325px; opacity:0; z-index:-1; }
	50% { top:-325px; opacity:0; z-index:-1; }
	92% { top:-325px; opacity:0; z-index:0; }
	96% { top:-325px; opacity:0; }
	100%{ top:0px; opacity:1; }
	
}
@keyframes cycletwo {
	0%  { top:-325px; opacity:0; }
	16% { top:-325px; opacity:0; }
	20% { top:0px; opacity:1; }
	24% { top:0px; opacity:1; } 
	36% { top:0px; opacity:1; z-index:0; } 
	40% { top:325px; opacity:0; z-index:0; }
	41% { top:-325px; opacity:0; z-index:-1; }  
	100%{ top:-325px; opacity:0; z-index:-1; }
}
@keyframes cyclethree {
	0%  { top:-325px; opacity:0; }
	36% { top:-325px; opacity:0; }
	40% { top:0px; opacity:1; }
	44% { top:0px; opacity:1; } 
	56% { top:0px; opacity:1; z-index:0; } 
	60% { top:325px; opacity:0; z-index:0; } 
	61% { top:-325px; opacity:0; z-index:-1; }
	100%{ top:-325px; opacity:0; z-index:-1; }
}
@keyframes cyclefour {
	0%  { top:-325px; opacity:0; }
	56% { top:-325px; opacity:0; }
	60% { top:0px; opacity:1; }
	64% { top:0px; opacity:1; }
	76% { top:0px; opacity:1; z-index:0; }
	80% { top:325px; opacity:0; z-index:0; }
	81% { top:-325px; opacity:0; z-index:-1; }
	100%{ top:-325px; opacity:0; z-index:-1; }
}
@keyframes cyclefive {
	0%  { top:-325px; opacity:0; }
	76% { top:-325px; opacity:0; }
	80% { top:0px; opacity:1; }
	84% { top:0px; opacity:1; }
	96% { top:0px; opacity:1; z-index:0; }
	100%{ top:325px; opacity:0; z-index:0; }
}

/* ANIMATION BAR */
@keyframes fullexpand {
    0%, 20%, 40%, 60%, 80%, 100% { width:0%; opacity:0; }
    4%, 24%, 44%, 64%, 84% { width:0%; opacity:0.3; }
   16%, 36%, 56%, 76%, 96% { width:100%; opacity:0.7; }
   17%, 37%, 57%, 77%, 97% { width:100%; opacity:0.3; }
   18%, 38%, 58%, 78%, 98% { width:100%; opacity:0; }	
}
@keyframes fullexpand {
    0%, 20%, 40%, 60%, 80%, 100% { width:0%; opacity:0; }
    4%, 24%, 44%, 64%, 84% { width:0%; opacity:0.3; }
   16%, 36%, 56%, 76%, 96% { width:100%; opacity:0.7; }
   17%, 37%, 57%, 77%, 97% { width:100%; opacity:0.3; }
   18%, 38%, 58%, 78%, 98% { width:100%; opacity:0; }	
}


/* RESET */






.image{
    position:absolute;
    top: -325px;
   
}


.content h1 {
	font-size:48px;
	color:white;
	text-shadow:0px 1px 1px #f4f4f4;
	text-align:center;
	padding:60px 0 30px;	
}

/* LAYOUT */
.viewer_container {
    display: flex;
    justify-content: center;
	justify-items: center;
	margin:auto;
	overflow:hidden;
	width:550px;
	
   
}

/* CONTENT SLIDER */
.content-slider {
	width:auto;
	height:360px;
	margin:10px auto 0;
}
/* SLIDER */
.slider {
    
	background: rgba(0,0,0,0.7);
	border:5px solid rgb(141, 138, 138, 0.2);
	border-radius: 5px;
	box-shadow:1px 1px 5px rgba(0,0,0,0.7);
	height:320px;
	width:680px;
	
	overflow:hidden;
	position:relative;
}
.mask {
	overflow:hidden;
	height:320px;
}
.slider ul {
	margin:0;
	padding:0;
	position:relative;
}
.slider li {
	width:680px;
	height:320px;
	position:absolute;
	top:-325px;
	list-style:none;
}

.slider li.firstanimation {
	animation:cycle 25s linear infinite;	
	animation:cycle 25s linear infinite;		
}
.slider li.secondanimation {
	animation:cycletwo 25s linear infinite;
	animation:cycletwo 25s linear infinite;		
}
.slider li.thirdanimation {
	animation:cyclethree 25s linear infinite;
	animation:cyclethree 25s linear infinite;		
}
.slider li.fourthanimation {
	animation:cyclefour 25s linear infinite;
	animation:cyclefour 25s linear infinite;		
}
.slider li.fifthanimation {
	animation:cyclefive 10s linear infinite;
	animation:cyclefive 25s linear infinite;		
}

.slider .tooltip {
	background:rgba(0,0,0,0.7);
	width:300px;
	height:60px;
	position:relative;
	top: -100px;
	transition:all 0.5s ease-in-out;
	transition:all 0.5s ease-in-out;  
}
.slider .tooltip h1 {
	color:#fff;
	font-size:24px;
	font-weight:300;
	line-height:60px;
	padding:0 0 0 20px;
}
.slider li.first:hover .tooltip, 
.slider li.second:hover .tooltip, 
.slider li.third:hover .tooltip, 
.slider li.fourth:hover .tooltip, 
.slider li.fifth:hover .tooltip {
	left:0px;
}
.slider:hover li, 
.slider:hover .progress_bar {
	animation-play-state:paused;
	animation-play-state:paused;
}

/* PROGRESS BAR */
.progress_bar { 
	position:relative;
	top:-5px;
	width:680px; 
	height:5px;
	background:#000;
	animation:fullexpand 25s ease-out infinite;
	animation:fullexpand 25s ease-out infinite;
}




/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .viewer_container {
		display: flex;
		justify-content: center;
		margin:auto;
		overflow:hidden;
		width:320px;   
	}
   
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

