.Banner_Container{
    display:flex;
    flex-direction: column;
    color:white;
    background-color:#333333;
    width:auto;
    height: auto;;
    box-shadow: 0px 0px 15px 0px black;
    z-index:4;
  

    
  
    
}

.Viewer_Container{
    display:flex;
    flex-direction:row;
    
    margin-left:25px;;
    border: 1px solid rgb(0,0,0,0.2);
    border-right-style: groove;
    border-right-width:10px;
    border-left:0px;
    background-color:rgb(0,0,0,0.5); 
    box-shadow: 0px 0px 5px 0px black;
   
    
 }
.Title{
    font-size:120px;
    font-family: 'Anton', sans-serif;
    border-radius:5px;
    width:50vw;
    height:auto;
    min-width:670px;
    line-height: 0px; 
     border: 1px solid rgb(0,0,0,0.2);
    border-left-style: groove;
    border-left-width:1px;
    background-color:rgb(0,0,0,0.2); 
    box-shadow: 0px 0px 5px 0px black;
    
}

.Bottom_Container{
    display:flex;
    flex-direction: row;
    padding:10px;
    width:50%;
   
   
}


.Banner_SocialMedia{

    font-family: 'Anton', sans-serif;
    text-align: center;

    
   
}

.Middle_Container{
    display:flex;
  
    flex-direction:row;
    
  
}


.Sub_Text{
    font-size: 15px;
    color: #cc9933;
    font-family: 'Anton', sans-serif;
    background-color:transparent;
   
   
}




.Ticker_Banner{
   display:flex;
   justify-content: center; 
}


.Small_Text{
    font-size: 70px;
    margin-left:35%;
    color: #cc9933;
    font-family: 'Anton', sans-serif;
    background-color:transparent;
    flex-grow:1;
   
}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .Ticker_Banner{
        visibility: hidden;
        
     }

 
   
     .Title{
        font-size:70px;
        font-family: 'Anton', sans-serif;
        border-radius:10px;
        width:auto;
        min-width:auto;
        border: 1px solid rgb(0,0,0,0.2);
        border-left-style: groove;
        border-left-width:5px;
        background-color:rgb(0,0,0,0.2); 
        z-index:3;
       
       
        
      
    }
    .Banner_SocialMedia{
        display:flex;
        margin-right:5%;
        justify-content:center;;   
    }


    .Sub_Text{
        font-size: 15px;
        color: #cc9933;
        font-family: 'Anton', sans-serif;
        background-color:transparent;
        margin:auto;
       
    }

    .Bottom_Container{
        display:flex;
        flex-direction: column;
        justify-content: center;
       
       
    }

    .Viewer_Container{
       width:auto;
       height:auto;
       display:flex;
       flex-direction:row;
       justify-content: center;
       z-index:0;
       overflow:hidden;
       margin-left:0;
       
      
       
      
       
    }

    .Middle_Container{
        display:flex;
        flex-direction:column;
        
      
    }

    .Small_Text{
        font-size: 38px;
        color: #cc9933;
        font-family: 'Anton', sans-serif;
        background-color:transparent;
        position:relative;
        bottom:25px;
       
       
    }

   

    

    
   
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

  

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .Viewer_Container{
        width:auto;
        height:auto;
        display:flex;
        flex-direction:row;
        justify-content: center;
        z-index:0;
       
        
        
       
        
       
        
     }


}






/*yellow : #cc9933*/